exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js-content-file-path-opt-build-repo-content-pages-about-about-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/content/pages/about/about.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-opt-build-repo-content-pages-about-about-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-dimensionality-reduction-pca-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/machine_learning_notes/Dimensionality Reduction/pca.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-dimensionality-reduction-pca-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-linear-regression-linear-regression-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/machine_learning_notes/linear_regression/linear-regression.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-linear-regression-linear-regression-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-mathematics-for-machine-learning-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/machine_learning_notes/mathematics-for-machine-learning.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-mathematics-for-machine-learning-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-support-vector-machines-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/machine_learning_notes/support-vector-machines.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-machine-learning-notes-support-vector-machines-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-old-content-workflow-system-content-workflow-system-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/old/content_workflow_system/content-workflow-system.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-old-content-workflow-system-content-workflow-system-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-old-mastering-baghchal-with-self-learning-ai-mastering-bagh-chal-with-self-learning-ai-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/old/mastering_baghchal_with_self_learning_ai/mastering-bagh-chal-with-self-learning-ai.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-old-mastering-baghchal-with-self-learning-ai-mastering-bagh-chal-with-self-learning-ai-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-packages-nepali-tokenizers-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/packages/nepali-tokenizers.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-packages-nepali-tokenizers-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-ugsrp-document-expansion-document-expansion-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/ugsrp/document-expansion/document-expansion.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-ugsrp-document-expansion-document-expansion-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-ugsrp-summary-of-papers-summary-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/ugsrp/summary-of-papers/summary.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-ugsrp-summary-of-papers-summary-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-ugsrp-ugsrp-2023-main-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/ugsrp/ugsrp-2023/main.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-ugsrp-ugsrp-2023-main-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-university-nyu-hpc-nyu-hpc-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/university/nyu-hpc/nyu-hpc.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-university-nyu-hpc-nyu-hpc-mdx" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

