import React, { useState, useEffect, createContext } from 'react'

export const ThemeContext = createContext({ theme: 'dark', onUpdateTheme: () => { } })

const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('dark')

    const onUpdateTheme = () => {
        const newTheme = theme === 'dark' ? 'light' : 'dark'
        window.localStorage.setItem('theme', newTheme)
        setTheme(newTheme)
        document.documentElement.className = newTheme === 'dark' ? 'dark' : '';
    }

    useEffect(() => {
        setTheme(document.documentElement.getAttribute('class') ? 'dark' : 'light')
    }, [])

    return (
        <ThemeContext.Provider value={{ theme: theme, onUpdateTheme: onUpdateTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

const Provider = ({ element }) => (
    <ThemeProvider>{element}</ThemeProvider>
)

export default Provider